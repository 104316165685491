<template>
<section class="goods-group">
    <div class="goods-header flex space-between">
        <h2 class="title">商品分组</h2>
        <p class="add-good" @click="dialogGroupVisible=true;">
            <span class="el-icon el-icon-plus"></span>
            <span>新增分组</span>
        </p>
    </div>

    <!-- table -->
    <el-table
        class="table-wrapper"
        :data="list"
        :header-cell-style="{color: '#000',fontWeigth: 'bold',background: '#FAFAFA',}"
        style="width:100%;">
        <el-table-column
            prop="groupName"
            label="分组名称"
            align="left"
            width="350">
        </el-table-column>
        <el-table-column
            prop="goods.length"
            align="center"
            label="商品数">
        </el-table-column>
        <el-table-column
            align="center"
            label="创建时间">
            <template slot-scope="scope">
                {{scope.row.updateTimeS*1000 | formatDate}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
            <template slot-scope="scope"> 
                <span class="table-btn" @click="addGoodHandler(scope.row)">添加商品</span> 
                <router-link class="table-btn" :to="{name:'groupDetail',query:{id: scope.row._id}}">详情</router-link>
                <span class="table-btn" @click="delGroupHandler(scope.row, scope.$index)">删除分组</span> 
                
            </template>
        </el-table-column>
    </el-table>

    <!-- 添加分组 -->
    <el-dialog title="新增分组" :visible.sync="dialogGroupVisible">
        <el-form :model="groupForm" :rules="groupRules" label-width="100px" ref="groupForm">
            <el-form-item label="分组名称" prop="name">
                <el-input v-model="groupForm.name" placeholder="请输入分组名称"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogGroupVisible = false">取 消</el-button>
            <el-button type="primary" @click="addGroupHandler">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 添加商品 -->
    <el-dialog title="添加商品" :visible.sync="dialogGoodVisible">
        <el-checkbox v-show="goodsList.length>0" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" label="全选"></el-checkbox>
        <el-checkbox-group v-model="checkedGoods" @change="checkedGoodsHandler">
            <div class="checkbox-item" v-for="(item,i) in goodsList" :key="i">
                <el-checkbox class="v-m" :label="item._id"></el-checkbox>
                <div class="good-info i-b v-m">
                    <el-image :src="item.mainImg" alt="" class="thumb v-m"></el-image>
                    <h3 class="title i-b v-m">{{item.productName}}</h3>
                </div>
            </div>
        </el-checkbox-group>
        <p v-if="goodsList.length==0">没有商品数据~</p>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addGoodCancelHandler">取 消</el-button>
            <el-button type="primary" @click="addGoodSureHandler">确 定</el-button>
        </div>
    </el-dialog>
</section>
</template>

<script>
export default {
    data(){
        return {
            list:[],
            dialogGroupVisible:false,
            dialogGoodVisible:false,
            groupForm:{
                name:''
            },
            groupRules:{
                name:[{required: true, message: '请输入分组名称', trigger: 'blur'}]
            },
            checkedGoods:[],
            allGoods:[],
            goodsList:[],
            currentId:'',
            isIndeterminate: false,
            checkAll: false,
        }
    },
    async created(){
        this.getGroupList();
        this.getAllGoods();
    },
    methods:{
        async getGroupList(){
            //  获取分组
            const res = await this.$https.cloud({
                                action:'group',
                                type:'list',
                            });
            if(res.code==0){
                this.list = res.data;
            }
        },
        async getAllGoods(){
            //  获取所有的商品
            const res = await this.$https.cloud({
                                action:'goods',
                                type:'all',
                            });
            if(res.code!=0) return;
            
            this.allGoods = res.data.data;
            const imgList = [];
            this.allGoods.forEach(item=>{
                imgList.push({fileid:item.mainImg});
            });
            const fileList = await this.$https.getImagePath(imgList);
            this.allGoods.forEach((item,i)=>{
                item.mainImg = fileList[i].download_url;
            });
            
        },
        addGoodHandler(data){
            //  打开添加商品弹窗
            const arr = [];
            this.allGoods.forEach(item=>{
                if(!data.goodsList.includes(item._id)){
                    arr.push(item);
                }
            });
            this.currentId = data._id;
            this.goodsList = arr;
            this.checkedGoods = [];
            this.dialogGoodVisible = true;
        },
         checkedGoodsHandler(value){
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.goodsList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.goodsList.length;
        },
        addGoodCancelHandler(){
            //  取消添加商品
            this.dialogGoodVisible = false; 
        },
        handleCheckAllChange(value){
            console.log(value);
            console.log(this.goodsList);
            if(value){
                this.goodsList.forEach(good=>{
                    this.checkedGoods.push(good._id);
                })
            }else{
                this.checkedGoods = [];
            }
            this.isIndeterminate = false;
            console.log(this.checkedGoods)
        },
        async addGoodSureHandler(){
            //  确认添加商品
            if(this.checkedGoods.length==0) {
                this.dialogGoodVisible = false; 
                return;
            }
            const rs = await this.$https.cloud({
                action:'group',
                type:'addGood',
                formData:{
                    _id: this.currentId,
                    goodsList: this.checkedGoods
                }
            })
            if(rs.code==0) this.getGroupList();
            this.$message(rs.msg);
            this.checkedGoods = [];
            this.dialogGoodVisible = false; 
            
        },
        async addGroupHandler(){
            //  添加分组
            this.$refs.groupForm.validate(async (valid)=>{
                if(!valid) return;
                const res = await this.$https.cloud({
                                action:'group',
                                type:'add',
                                formData:{
                                    groupName: this.groupForm.name
                                }
                            });
                if(res.code==0){
                    this.$message.success('添加分组成功');
                    this.dialogGroupVisible=false;
                    this.getGroupList();
                }else{
                    this.$message.error('添加分组失败');
                }
            })
        },
        async delGroupHandler(data,index){ 
            console.log(data,index);
            if(data.isSystemValue){
                this.$message.error('该分组不能删除');
                return;
            }
            this.$confirm("确认删除该分组？", "提示", {
                type: "warning"
            }).then(async ()=>{
                const res = await this.$https.cloud({
                                action:'group',
                                type:'delGroup',
                                formData:{
                                    _id: data._id
                                }
                            });
                if(res.code==0){
                    this.$message.success('删除分组成功');
                    this.list.splice(index,1);
                }else{
                    this.$message.error('删除分组失败');
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

.goods-group {
    padding:  0 10px;
    background: #fff;
    
    .goods-header {
        line-height: 68px;
        align-items: center;
        background: #fff;
        
        .title {
            font-size: 16px;
        }

        .add-good {
            display: inline-block;
            width:111px;
            height:32px;
            line-height: 32px;
            border: 1px solid $baseColor;
            border-radius:4px;
            font-size: 14px;
            color: $baseColor;
            text-align: center;

            
        }
    }

    .table-wrapper {
        border-top: 1px solid #ccc;

        .table-img {
            width: 45px;
            margin-right: 0.8rem;
        }

        .table-name {
            font-size: 14px;
            line-height: 22px;
            flex: 1;
        }

        .table-btn {
            color: $baseColor;
            padding: 0 6px;
        }
    }

    .checkbox-item {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        ::v-deep.el-checkbox__label {
            display: none;
        }

        .good-info {
            margin-left: 10px;
        }

        .thumb {
            width: 44px;
            height: 44px;
            margin-right: 10px;
        }

        .title {
            font-size: 14px;
            line-height: 22px;
        }
    }
}
</style>